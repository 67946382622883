<template>
  <!-- <div>
      <div style="margin-top: 20%">
        
          <van-field v-model="value" placeholder="请输入MSISDN、ICCID系统将自动检索" />
          <van-button type="info" block style="margin-top:20px" @click="submitMsisdn">查询</van-button>
      </div>
  </div> -->
  <div>
  <div class="main">
    <!-- <div class="search-box">
      <input class="search-input" v-model="search" :placeholder="placeholder">
      <input class="search-btn" type="button" value="查询续费" @click="submitMsisdn()">
    </div> -->
    <div style="margin-top: 20%">
        
          <van-field v-model="value" placeholder="请输入MSISDN、ICCID系统将自动检索" />
          <van-button type="info" block style="margin-top:20px" @click="submitMsisdn">查询</van-button>
      </div>
    <p class="main-text reds" style="margin-top: 20px;">请核对本次充值的卡号是否正确，如充值号码不正确请重新输入，充值成功将无法退还。</p>
    <!-- <p class="main-text">温馨提示：系统自动记住您最近查询成功的号码</p> -->
  </div>
  <div class="msg">
    <img class="msg-pho" src="~@/assets/img/qr.png">
    <p class="msg-text">点击“扫码输入”，对着卡的条形码扫一扫</p>
  </div>
</div>
</template>

<script>
import md5 from 'js-md5';
export default {
    name: 'Home',
    data() {
        return {
            value:'',
            search:'',
			placeholder:'手动输入13位卡号或20位iccid',
			codetrue:false,
			config:{}
        }
    },
    methods:{
        toDetails() {
            this.$router.push('/card/info');
        },
        submitMsisdn(){
            let uuid = localStorage.getItem("uuid");
            localStorage.setItem("sign",md5(md5(this.value + uuid) + uuid));
            this.$api.getCardInfo({cardNumber:this.value}).then(res => {
            if(res.data.code == 0){
                if(!!res.data.data && res.data.data.length != 0){
                    localStorage.msisdn = this.value;
                    this.$router.push('/card/info');
                }else{
                    this.$dialog.alert({message: '未查询到卡号，请重新输入'})
                }
            }
            
        })
        }
    },
    created(){
        this.$store.commit('setPageTitle', "查询卡号");
    }

}
</script>

<style lang="scss" scoped>
p{
    font-size: 30px;
}
.van-cell{
    font-size:0.5rem;
}



.search-box{
	position:relative;
	width:85%;/*604px*/
	margin:2.4rem auto 0;/*180px*/
}

.search-input{
	width:94%;/*498px*/
	height:2rem;/*78px*/
	border:1px solid #1781ef;/*2px*/
	border-radius:0.3rem;/*12px*/
	font-size:0.8rem;/*28px*/
	padding:.2rem 3%;/*0 82px 0 20px*/
}

.code-icon{
	background-image:url('~@/assets/img/code.png');
	background-size:cover;
	width:1.9rem;/*62px*/
	height:1.9rem;/*62px*/
	position:absolute;
	top:0.3rem;/*9px!Warning*/
	right:0.3rem;/*12px*/
}

.search-btn{
	width:100%;/*604px*/
	height:2.6rem;/*83px*/
	border: none;
	background-color:#197fee;
	border-radius:0.3rem;/*12px*/
	font-size:1rem;/*38px*/
	color:#fff;
	display:block;
  margin-top: 1rem;
}

.main-text,
.msg-text{
	text-align:center;
	font-size:20px;/*24px*/
	color:#838383;
}

.msg{
	margin-top: 10px;
	width:100%;
	float:left;
	/* background-color:#f6f6f6; */
	overflow: hidden;
}

.msg-pho{
	width:80%;/*427px*/
	display:block;
	margin:2rem auto ;/*60px*/
}
.reds{
	color: red;width: 80%;margin: 10px auto;
}


</style>
